/* drawer.css */
.drawer {
    position: fixed;
    top: 0;
    left: -350px;
    /* Hide the drawer initially */
    width: 350px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    transition: left 0.3s ease;
    z-index: -1;
    
}

.drawer.open {
    left: 80px;

}
.display_draw h4{
font-weight: 400;
font-family: "Lalezar";
font-size: 24px;
}
.drawer_add_project{
        background-color: black;
        border-radius: 50%;
        padding: 20;
        cursor: pointer;
        display:flex;
        justify-content: center; 
        cursor: pointer;    

}
.drawer-content{
    overflow-x:hidden;
}
.drawer_add_project_icon{
    color: white;
    padding: 5px;
    height: 20px !important;
    width: 20px !important;
}
.drawer_search_input{
    display: flex;
    font-weight: 500 !important;
    font-family: "Inter", sans-serif;
color:#E5E5E5
}
.drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-project-button {
    background-color: black;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}


.add-project-button .plus-icon {
    color: white;
    font-size: 24px;
}

.divider {
    background-color: #e5e5e5;
    border: 1px solid #e5e5e5;
    margin: 15px 0;
}

.search-bar {
    display: flex;
    align-items: center;
}

.search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
}

.search-button {
    padding: 10px;
    border: none;
    background-color: #2196f3;
    color: white;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.projects-list {
    max-height: 650px;
    overflow-y: auto;
}

.project-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.project-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.no-projects-message {
    text-align: center;
    padding: 20px;
}

.drawer-toggle-button {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1001;
    padding: 10px 20px;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media screen and (min-width:1200px) {
    .url_input {
        width: 400px;
        margin-top: 5px;

    }

}

.hint{
    font-size: 12px;
}
.warn{
    width: 400px;
}