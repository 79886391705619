.Parent-div {
    display: flex;
    background-color: whitesmoke;
}

.side-nav {
    width: 50px;
    background-color: black;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-height: 100vh;
    max-height: 100%;
}

.side-nav-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.side-nav-img-1 {
    background-color: white;
    padding: 14px;
    border-radius: 10px;

}

.side-nav-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 70px;
}

.side-nav-img-2 {
    background-color: #FFFFFF1A;
    padding: 20px;
    border-radius: 10px;
}

.head-nav {
    flex: 1;
    width: 100%;
}

.main-div {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px 25px;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    position: relative;
}
.dropdown{
    position: absolute;
    right: 200px;
    top: 73px;
    background-color: #ffffff;
    border: 1px solid rgba(221, 232, 236, 1);
    padding: 10px;
    border-radius: 8px;
    z-index: 99
}
.dropdown-div1{
    padding: 20px;
    cursor: pointer;
   
}
.dropdown-h1{
    font-weight: 400;
    font-size: 24px;
    color: rgba(0, 0, 0, 1);
}
.dropdown-p1{
    font-weight: 600;
    font-size: 14px;
    color: rgba(133, 138, 148, 1);
    margin-top: 10px;
}
.Active-div{
    background-color: #edf6ff;
    border-radius: 8px;
    padding:10px    20px;
}
.active-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-h2{
    font-weight: 800;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}
.dropdown-p2{
    font-weight: 400;
    font-size: 14px;
}
.dropdown-h3{
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}
.dropdown-p3{
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    margin-top: 10px;
}
.dropdown{
    position: absolute;
    right: 0px;
    top: 60px;
    background-color: #ffffff;
    border: 1px solid rgba(221, 232, 236, 1);
    padding: 10px;
    border-radius: 8px;
    width: 580px;
    z-index: 1;
    cursor: pointer;
}
.dropdown-div1{
    padding: 20px;
}
.dropdown-h1{
    font-weight: 400;
    font-size: 24px;
    color: rgba(0, 0, 0, 1);
}
.dropdown-p1{
    font-weight: 600;
    font-size: 14px;
    color: rgba(133, 138, 148, 1);
    margin-top: 10px;
}
.Active-div{
    background-color: #edf6ff;
    border-radius: 8px;
    padding:10px    20px;
}

.Active-div-voice{
    background-color: #edf6ff;
    border-radius: 8px;
    padding:10px    20px;
}
.active-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-h2{
    font-weight: 800;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-p2{
    font-weight: 400;
    font-size: 14px;
}
.dropdown-h3{
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}
.dropdown-p3{
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    margin-top: 10px;
}
.div-1 {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    font-size: 18px;
    font-weight: 700;
}

.project-name{
    font-weight: 700;
    font-size: 18px;
    font-family: 'Inter' 
}

.div-1-img-1 {
    width: 50px;
    height: 50px;
    /* border: 1px solid #E5E5E5;
    border-radius: 50px; */
}

.div-1-img-2 {
    width: 20px;
    height: 20px;
    background-color: #F6F6F6;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
}
.div-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #F6F6F6;
    padding: 13px 17px;
    border-radius: 5px;
    position: relative;
    width: 40%;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Inter';
}

.div-2-img-1 {
    background-color: #F6F6F6;
    border-radius: 23px;
    padding: 5px;
    width: 12px;
    height: 14px;
    cursor: pointer;
}

.main-div-2 {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
    width: 100%;
}

.newDoc {
    background-color: black;
    color: white;
   padding: 18px 10px ;
    border-radius: 6px;
    cursor: pointer;
    width: 25%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter"
}
/* @media screen and (max-width:1000px){
    .main-div{
        flex-direction: column;
        gap: 20px;
    }
    .div-1{
        width: 100%;
        justify-content: center;
    }
    .div-2{
        width: 100%;
    }
    .main-div-2{
        flex-direction: column-reverse;
    }
    .newDoc{
        width: 97%;
    }
    .dropdown{
        width: 95%;
    }
} */
.Content {
    padding-left: 60px;
    padding-top: 40px;

}

.card-1 {
    background: linear-gradient(108.58deg, #70C3FF 0%, #74FFD3 107.36%);
    padding: 25px;
    color: white;
    width: 50%;
    border-radius: 20px;
    position: relative;
}
.main-keyword-text{
    font-size: 12px;
    font-weight: 600;
    font-family: "Inter";
}

.article-1 {
    font-size: 24px;
    font-weight: 400;
    font-family: "Lalezar";
    margin-top:  10px;
}

.content-1 {
    display: flex;
    margin-top: 50px;
    gap: 55px;
}

.content-div-1 {
    background-color: white;
    color: black;
    padding: 12px 20px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 700;
    font-size: 12px;
    font-family: "Inter";
}

.content-div-2 {
    display: flex;
    align-items: center;
    color: black;
    gap: 7px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 700;
    font-family: "Inter";
    cursor: pointer;
}

.cards {
    display: flex;
    gap: 25px;
    align-items: center;
}

.Content-2 {
    flex-direction: column;
    height: 88vh;
    width: 29%;
    display: flex;
    align-items: center;
}

.scroll-card{
    overflow: hidden scroll;
    width: 85%;
    padding-right: 10px;
}

.scroll-card::-webkit-scrollbar {
    width: 10px;
    background-color: black;
}

.scroll-card::-webkit-scrollbar-button {
    background-color: whitesmoke;
}

.scroll-card::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
}

.scroll-card::-webkit-scrollbar-track {
    background-color: whitesmoke;
}

.head {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 87%;
}

.head-1 {
    font-size: 24px;
    font-weight: 400;
    font-family: "Lalezar";
}

.head-2 {
    color: var(--Sky-Blue, #4BA9FF);
    font-size: 12px;
    font-weight: 700;
    font-family: "Inter";
    margin-right: 20px;
}

.cnt-2-div-1 {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
}

.rightContent {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 20px;
    width: 100%;
    font-family: "Lalezar";
}

.main {
    display: flex;
}

.cnt-2-1 {
    color: #979797 !important;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    font-family: "Inter";
}

.cnt-2-1 a {
    color: #979797 !important;
}

.no-card-message {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    text-align: center;
    height: 77.5vh;
}


.main {
    display: flex;
}


.cnt-2-2 {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Inter';
    margin-top: 8px;
    margin-bottom: 5px;
    padding: 5px 0px;
}

.cnt-2-3 {
    color: #979797;
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter";
}

.cnt-date{
    color: #979797;
    font-size: 12px;
    font-weight: 700;
    font-family: "Inter";
}

.cnt-2-4 {
    margin-top: 70px;
    color: #979797;
    font-size: 12px;
    font-weight: 700;
    font-family: "Inter";
}

.cnt-2-5 {
    font-weight: 400;
    font-size: 38px;
    font-family: 'Lalezar';
}

.cnt-2-6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 20px;
    width: 90%;
    font-size: 12px;
    font-weight: 700;
    font-family: "Inter";
}

.cnt-2-7 {
    color: #ED65B7;
    background-color: #FFF0F9;
    border-radius: 5px;
    padding: 5px;
}

@media only screen and (max-width: 1252px) {
    .cards {
        width: 100%;
        padding: 0;
        margin: 0;
        gap: 0;
    }

    .card-1 {
        width: 100%;
        margin-left: 10px;
        padding: 10px;
        border-radius: 10px;
    }

    .main {
        flex-flow: wrap column;
    }

    .cnt-2-div-1 {
        width: 90%;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 0px;
        margin-left: 18px;
        margin-right: 0px;
    }

    .rightContent {
        margin-top: 1.5rem;
        margin-left: 1rem;
    }
    
    .scroll-card{
      width: 95%;
    }
    .head {
        margin-top: 1.5rem;
        width: 97%;
    }

    .head-1 {
        margin-left: 19px;
    }

    .head-2 {
        margin-right: 20px;
    }

    .Content {
        padding: 0;
    }

    .newDoc {
        padding: 15px;
        width: 70%;
    }

    .div-2 {
        width: 100%;
        margin: 0;
        padding: 9px;
        gap: 35px;
    }

    .main-div-2 {
        width: 62%;
    }

    .Content-2 {
        width: 40%;
        padding: 0;
        margin: 0;
    }
    .content-1 {
        gap: 15px;
    }
    .content-div-1 {
        font-size: 10px;
        padding: 10px;
    }
    .content-div-2 {
        font-size: 10px;
        margin-right: 0;
    }
    .article-1{
        font-size: 20px;
    }
}

.random-popover-content-right {
    width: 430px;
    height: 250px;
    background-color: #262626;
    color: #ffffff;
    line-height: 20px;
    text-align: left;
    border-radius: 15px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Titillium Web";
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
  }

  .random-popover-content-right::after {
    content: "";
    position: absolute;
    top: 30px;
    left: -15px;
    right: 0px;
    width: 30px;
    height: 30px;
    background-color: #262626;
    border-top: none;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transform: rotate(225deg);
  }
  .firstProjectText{
    font-size: 24px;
    font-family: 'Lalezar';
    font-weight: 400;
  }
  .firstProjectPara{
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    margin-top: 20px;
  }
  .showMsgCheckBox{
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .showMsgCheckBox {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
}


.showMsgCheckBox input[type="checkbox"] {
    appearance: none;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s;
}


.showMsgCheckBox input[type="checkbox"]:checked {
    background-color: white;
    border-color: white;
}


.showMsgCheckBox input[type="checkbox"]:checked::after {
    content: '\2714';
    position: absolute;
    top: -3px;
    left: -1px;
    font-size: 25px;
    font-weight: bolder;
    color: black;
}
.showMsgCheckBoxButton{
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    border: 1px solid white;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
}
.showMsgFotterFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.showMsgPositionAdjust{
    position: absolute;
    top: -16px;
    right: -275px;
}
.positioning{
    position: relative;
}
.ribbon {
    /* height: 188px; */
    position: relative;
    margin-bottom: 30px;
    background-color: skyblue;
    background-size: cover;
    text-transform: uppercase;
    color: white;
    position: absolute;
    right:1rem;
    top: 0;
  }
  .ribbon1 {
    position: absolute;
    top: -6.1px;
    right: 10px;
  }
  .ribbon1:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 53px solid transparent;
    border-right: 53px solid transparent;
  }
  .ribbon1 span {
    position: relative;
    display: block;
    text-align: center;
    background: rgb(38, 38, 38);
    font-size: 14px;
    line-height: 1.5;
    padding: 12px 8px 10px;
    width: 90px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .ribbon1 span:before,
  .ribbon1 span:after {
    position: absolute;
    content: "";
  }
  .ribbon1 span:after {
    height: 6px;
    width: 6px;
    left: -6px;
    top: 0;
    border-radius: 8px 0px 0 0;
    background: linear-gradient(45deg, transparent, black);
  }
  .ribbon1 span:before {
    height: 6px;
    width: 6px;
    right: -5.9px;
    top: 0;
    border-radius: 0px 8px 0px 0;
    background: linear-gradient(3deg, transparent, black);
  }
  @media (min-width: 500px) {
    .ribbons-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
}

.block {
    display:block;
    width:82px;
    height:12px;
    border-radius: 4px;
    color: black;
  }
  .block-2 {
    display:block;
    width:213px;
    height:15px;
    border-radius: 4px;
    color: black;
  }
  .block-3 {
    display:block;
    width:140px;
    height:12px;
    border-radius: 4px;
    color: black;
  }
  #cardSkeleton {
    box-sizing: border-box;
  }
  @-webkit-keyframes Gradient {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  
  @-moz-keyframes Gradient {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  
  @keyframes Gradient {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  .pulsate{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.61) 0%, rgba(255, 255, 255, 0.18) 100%);
      background-size: 400% 400%;
      -webkit-animation: Gradient 2.25s ease infinite;
      -moz-animation: Gradient 2.25s ease infinite;
      animation: Gradient 2.25s ease infinite;
  }
  .scratchImg{
    padding : 5px;
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
  }

.status-project-dropdown{
  background-color: #ffffff;
  border: 1px solid rgba(229, 229, 229, 1);
  padding: 10px;
  border-radius: 4px;
  width: 315px;
  top: 35px;
  left: -15px;
  position: absolute;
  text-align: left;
  z-index: 9999999999;
  cursor: pointer;
}
.status-padding {
  padding: 15px 0px;
}

.status-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: bold;
}
.dropdown-project-status{
  position: relative;
}