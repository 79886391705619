.Keyword-Container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    position: absolute;
}

.showcontainer {
    left: 50%;
}

.img-1 {
    border: 1px solid #ECECEC;
    padding: 6px;
    border-radius: 19px;
    width: 20px;
    height: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.top {
    display: flex;
    align-items: center;
    gap: 10px;
}

.keyword-modal {
    font-size: 30px;
    font-weight: bolder;
    margin-top: 20px;
}

.content {
    margin-top: 18px;
}

.search {
    display: flex;
    align-items: center;
    border: 1px solid #E5E5E5;
    width: 62%;
    justify-content: space-between;
    padding: 2px;
    border-radius: 5px;
    margin-top: 35px;
    cursor: pointer;
}

input {
    border: none;
    padding: 10px;
    color: #000;
    outline: none;
    width: 100%;
}

.img-2 {
    background-color: #F6F6F6;
    width: 15px;
    padding: 8px;
    border-radius: 16px;
}

.head-keyword {
    display: flex;
    justify-content: space-between;
    background-color: #F6F6F6;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 900;
    padding: 20px;
    border-radius: 8px;
}

.h-1 {
    width: 60%;
    font-size: 12px;
    font-family: "Inter";
}

.h-2 {
    width: 20%;
    font-size: 12px;
    font-family: "Inter";
    text-align: center;
}

.h-3 {
    width: 20%;
    font-size: 12px;
    font-family: "Inter";
    text-align: center;
}

.modal-body {
    margin-top: 20px;
    height: 300px;
    overflow: hidden scroll;
}

.modal-body::-webkit-scrollbar {
    width: 8px;
    background-color: black;
    border-radius: 20px;

}


.modal-body::-webkit-scrollbar-button {
    background-color: white;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
}

.modal-body::-webkit-scrollbar-track {
    background-color: white;
}

.checkbox {
    height: 24px;
    width: 24px;
    margin-right: 20px;

}

.row-1 {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 2px;

}

.rows {
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    padding: 8px;
}

.text {
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter";
}

.col-2 {
    width: 20%;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    display: flex;
    justify-content: center;
}

.col-3 {
    display: flex;
    width: 20%;
    font-size: 12px;
    font-weight: 700;
    align-items: center;
    font-family: "Inter";
    justify-content: center;
}



.fotter {
    background-color: #F6F6F6;
    margin-top: 18px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0px;

}

.key-nmbr {
    width: 22px;
    border-radius: 19px;
    padding: 8px;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    font-family: "Inter";
    font-size: 12px;
}

.nmbr-8 {
    background-color: #F9E292;
    width: 20px;
    border-radius: 19px;
    padding: 9px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
}

.nmbr-22 {
    background-color: #FFCB9B;
    width: 20px;
    border-radius: 19px;
    padding: 9px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
}

.nmbr-26 {
    background-color: #FFCB9B;
    width: 20px;
    border-radius: 19px;
    padding: 9px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
}

.nmbr-66 {
    background-color: #FF8888;
    width: 20px;
    border-radius: 19px;
    padding: 9px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
}

.mrgn {
    margin: 0px 25px;
}

.btn-2 {
    cursor: not-allowed;
    background-color: #979797;
    padding: 12px 24px 12px 24px;
    color: white;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    margin-right: 40px;
    font-family: "Inter";
}

.btn-2.active {
    background-color: black; /* Active state color */
    cursor: pointer;
}

.btn-2.disabled {
    background-color: grey; /* Disabled state color */
    cursor: not-allowed;

}

