.myaccountContainer{
    width: 100%;
}
.accountHead{
    height: 80px;
    background-color: white;
    display: flex;
}
.accountText{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-left: 91px;
    border-bottom:4px solid rgba(75, 169, 255, 1) ;
    width: 150px;
    text-align: center;
    font-family: "Inter";
}
.accountBody{
    width: 90%;

}
.accountHeading{
    font-size: 24px;
    font-weight: 400;
    margin-top: 35px;
    font-family: "Lalezar";
}
.accountBodyflex{
    display: flex;
    justify-content: center;
}
.accountInfo{
    width: 100%;
    /* height: 360px; */
    border-radius: 8px;
    background: white;
}
.accountInfoEdit{
    padding: 35px 20px;
    margin-top: 30px;
}
.accountInfoEditFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accountLeftFlex{
    display: flex;
    align-items: center;
    gap: 50px
}
.accountRightFlex{
    display: flex;
    align-items: center;
    width: 290px;
    /* justify-content: space-between; */
    font-family: "Inter";
    gap: 15px

}
.subProFlex{
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: black;
    color: white;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
}
.accountProfilePicCam{
    position: relative;
    cursor: pointer;

}

.accProfilePicChange{
    width: 80px;
    height: 80px;
    border-radius: 50px;
    object-fit: cover;
}
.profileCamera{
    position: absolute;
    top: 0px;
    right: -25px;
}
.profileOwnerName{
    font-size: 18px;
    font-weight: 700;
    gap: 5px;
    display: flex;
    flex-direction: column;
    font-family: "Inter";
    
}
.profileOwnerPlanName{
    font-size: 14px;
    font-weight:500;
}
.profileEditButton{
    cursor: pointer;
}
.editaccountinfoText{
    font-size: 12px;
    font-weight: 700;
    color: rgba(75, 169, 255, 1);
    cursor: pointer;
    font-family: "Inter";

}
.accountDetails{
    padding: 20px;
    font-family: "Inter";
    border-top: 1px solid rgba(229, 229, 229, 1);


}
.accountNameEmailFlex{
    display: flex;
    gap: 30px;
}
.accountNameEdit{
    font-size: 12px;
    font-weight: 600;
}
.accountEmailEdit{
    font-size: 12px;
    font-weight: 600;
    font-family: "Inter";

}
.accountInputText{
    background: transparent;
    outline: none;
    border: none;
    width: 94%;
}
.accountEmailInputText{
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    font-family: "Inter";
    color: #949494;
    font-size: 12px;
    font-weight: 500;
}
.accountInputTextBorder{
    border: 1px solid rgba(229, 229, 229, 1);
    padding: 10px;
    width: 300px;
    border-radius: 4px;
}    
.accountEmailInputTextBorder{
    border: 1px solid rgba(229, 229, 229, 1);
    padding: 10px;
    width: 300px;
    border-radius: 4px;
}    
.accountNameFlex{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.accountEmailFlex{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.accountPassChngbtnsFlex{
    display: flex;
    align-items: center;
    gap: 30px;
}
.accountPassChngbtn{
    margin-top: 30px;
    border: 1.5px solid black;
    border-radius: 4px;
    cursor: pointer;
    width: 136px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    background-color: white;
    font-family: "Inter";
}
.accountPassSavebtn{
    margin-top: 30px;
    border: 1.5px solid black;
    border-radius: 4px;
    cursor: pointer;
    width: 100px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    background-color: white;
    font-family: "Inter";
   
}
.buyWordsbtn{
    margin-top: 30px;
    border: 1.5px solid black;
    border-radius: 4px;
    cursor: pointer;
    width: 136px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter";
    background-color: white;
}
.buyWordsbtnFlex{
    display: flex;
    justify-content: end;
}


.accountUsageText{
    font-size: 24px;
    font-weight: 400;
    margin-top: 50px;
    font-family: "Lalezar";

}
.accountUsageTopFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.accountUsageTextWord{
    font-size: 18px;
    font-weight: 700;
    font-family: "Inter";

}
.accountUsageTextFlex{
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: "Inter";

}
.percentageFlex{
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Inter";

}
.usageNmbrsFlex{
    display: flex;
    align-items: baseline;
    font-family: "Lalezar";

}
.usageNmbrInc{
    font-size: 38px;
    font-weight: 400;
}
.usageNmbrTotal{
    font-size: 24px;
    font-weight: 400;
    color: rgba(151, 151, 151, 1);
}
.positionBorder{
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 30px;
    width: 100%;
    height: 40px;
    position: relative;
    margin-top: 30px;
}
.positionRectanle{
    position: absolute;
    bottom: 4px;
    left: 5px;
}
.accountUsageDetailsFlex{
    display: flex;
    gap: 20px;
    align-items: center;
}
.accountUsageCardBgclr{
    background-color: white;
    width: 100%;
    border-radius: 10px;
    padding: 40px 20px 20px 20px;
}




.n-pass,
.c-pass,
.confirm-pass {
    width: 450px;
}
.margin-passwords{
    margin-top: 20px;
}
.pass-label{
    display: block;
    margin-bottom: 10px;
}

.password-modal-footer{
    background-color: #f6f6f6;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: 1px solid #f0f0f0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 30px;
    padding: 16px;
}

.progressBarProfile {
    width: 100%;
    height: 30px; /* Adjust the height as needed */
    margin-top: 10px; /* Space above the progress bar */
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 30px;
    margin-top: 30px;
    text-align: center;
    padding: 5px;
}
.progressBarFill {
    height: 100%;
    background: linear-gradient(90deg, #FFB84D 0%, #FFFA83 48.28%, #5BF38F 95.6%);
    border-radius: 5px; /* Match the rounded corners */
    transition: width 0.3s; /* Smooth transition for width change */
    border-radius: 30px;
}
.progressBarFillImages {
    height: 100%;
    background: linear-gradient(90deg, #FFFA83 0%, #5BF38F 51.14%, #5BF3F3 95.6%);
    border-radius: 5px; /* Match the rounded corners */
    transition: width 0.3s; /* Smooth transition for width change */
    border-radius: 30px;
}