.modal-content {
  padding: 15px;
}

.heading {
  font-family: "Lalezar";
  font-size: 38px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  margin: 5px;
}

.select_div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 400px;
  font-size: 14 !important;
}
.select_div .ant-select-selector {
 
    padding: 15px 16px !important;
    height: 50px !important;
    font-size: 12px;
    font-weight: 400;
}
.textarea_class{
    margin-top: 15px;
    width: 100%;
    resize: none !important;
    height: auto !important;
    padding: 15px 16px;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.link_tag {
  color: #4BA9FF;
  font-weight: 700;
  font-size: 12px;
  font-family: "Inter";
}

.p-text {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  color: #000000;
  width: 443px;
  margin-top: 10px;
  margin-bottom: 30px !important;
}

.footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  /* Align button to the right */
  border-top: 1px solid #f0f0f0;
  /* Optional border for separation */
  background-color: #f6f6f6;
  padding: 16px;
  margin-bottom: 10px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.keyword {
  width: 400px !important;
  /* Set width */
  height: 50px !important;
  /* Set height */

  margin: 0px !important;
  margin-top: 10px !important
}

.react-select__control {
  height: 50px;
}

.react-select__multi-value {
  height: 30px;
  line-height: 30px;
}

.react-select__multi-value__label {
  font-size: 14px;
}

.react-select__multi-value__remove {
  cursor: pointer;
}

.react-select__multi-value__remove:hover {
  background-color: red;
  color: white;
}

.keyword .css-1wy0on6 {
  display: none;
}

.keyword .css-1nmdiq5-menu {
  margin: 0px;
  top: 42px
}

.keyword-modal .ant-modal-close-x {
  display: none;
}