.main_container {
  display: flex;
}

.side_bar{
  z-index: 2;
}

body {
  background-color: #f6f6f6 !important;
}

.logo {
  margin-top: 20px;
}

.register-main-container {
  margin-top: 220px !important;
  /* height: 36.3vh; */
}

.register-main-container h1 {
  font-family: "Lalezar";
  font-size: 38px;
  font-weight: 400;
}

.forget-main-container{
  margin-top: 215px !important;
}

.login-main-content {
  margin-top: 220px;
  /* height: 36.8vh; */
}

.menu_items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  min-height: 100vh !important;
  max-height: 100%;

  top: 0;
  left: 80px !important;
  background-color: unset !important;
  -webkit-tap-highlight-color: transparent;
  z-index: 0;
  overflow: hidden;
}

.main_container_sidebar {
  width: 80px;
  min-height: 100vh;
  max-height: 100%;
  background-color: #262626;
  display: flex;
  justify-content: center;
}

.sidebar_img_new {
  bottom: 0;
    position: absolute;
    z-index: -1;
    width: 700px;
}

.dashboardsidemain_new {
  width: 440px;
  background-color: black !important;
  min-height: 100vh;
  max-height: 100%;
  position: relative;
  z-index: 2;
}

.dashboardheader {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 25px;
  color: white;
  font-weight: bolder;
}

.centerheadertextdashboard {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  margin-top: 175px;
  width: 100%;
  max-height: 100%;
}

.centerheadertextdashboard h1 {
  background: linear-gradient(to right,
      rgba(75, 169, 255, 1),
      rgba(92, 236, 184, 1));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 90px;
  font-weight: 400;
  font-family: "Lalezar";
  margin: 0;
}

.centerheadertextdashboard h2 {
  color: white;
  font-size: 60px;
  font-weight: 400;
  margin: 0;
  font-family: "Lalezar";
}

.roundimgdash img {
  position: absolute;
  bottom: 100px;
  height: 200px;
  width: 400px;
}

.content-container-main_new {
  max-height: 100%;
  min-height: 100vh;
  z-index: 5;
  position: relative;
}

.content-container_new {
  margin-left: 200px;
  margin-top: 230px;


}

/* .sidebar-dashboard {
  width: 30%;
} */

.content-container {
  width: 40%;
  display: flex;
  align-items: center;
}

.maindashboardparent {
  display: flex;
  min-height: 100vh;
  max-height: 100%;
}

.loginsplashheading {
  margin-top: 285px !important;
  /* height:30vh; */
}

.loginsplashheading h1 {
  font-size: 38px;
  font-weight: 400;
  font-family: "Lalezar";
}

.continuewithemail {
  background-color: white;
  width: 30vw;
  height: 50px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  gap: 15px;

  cursor: pointer;
}

.content-container-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-container-main-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.color_gray {
  color:#979797;
  font-size: 14px;
  font-weight: 700;

  font-family: "Inter", sans-serif;
}

.auth_footer_h3 {
  font-size: 14px;
  color: #262626;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.forgetHead_text {
  font-family: "Lalezar";
  font-size: 38px;
  font-weight: 400 !important;
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

.color_blue {
  color: rgba(75, 169, 255, 1);
  font-weight: bold;
  text-transform: uppercase;
}

.color_blue2 {
  color: rgba(75, 169, 255, 1);
  font-weight: 600;

}

.login_text {
  font-size: 38px;
  font-weight: 400 !important;
  font-family: "Lalezar";
}

.privacycontainer {
  /* margin-top: 300px;
  margin-left: 200px; */
  position: absolute;
  bottom: 30px;
  left: 200px;
}

.privacycontainer h3 {
  font-size: 14px;
  font-weight: 400;
}

.login-main-content,
.register-main-container {
  width: max-content;
}

.login-main-content h1 {
  font-size: 38px;
  font-weight: 400;
  width: 100%;
  font-family: "Lalezar";
}

.ant-input-outlined {
  height: 50px !important;
  font-size: 12px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  width: 500px;
}

.ant-select {
  height: 50px !important;
  font-size: 12px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
}

.margin-top {
  margin-top: 20px;
}

.forgotpass {
  float: right !important;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.createaccountbtn {
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 15px;
  border-radius: 7px;
  min-width: 100px;
  font-family: "Inter", sans-serif;
}

.backarrow,
.back-arrow {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 15px;
}

/* --------------------------------toastStyles.css---------------------------- */

.toast-container .Toastify__toast--success {
  background-color: #4caf50 !important;
  color: white;
}

.toast-container .Toastify__toast--error {
  background-color: #FF6174 !important;
  color: white;
}

.Toastify__toast-icon svg {
  fill: white !important;
}

.ant-modal-close-x .anticon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -1.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-modal-close {
  top: -60px !important;
}

.ant-modal .ant-modal-content {
  border-radius: 20px;
  padding: 0 !important;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.7);
}

.select_countries {
  height: 45px !important;
  width: 50% !important;
}

.img_round_main_dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.content_container_splash {
  width: 100%;
  background: url("../src/assets/dashboardBg.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.content_container2{
  width: 100%;
  z-index: 1;
}



.dashboardheader {
  height: 70px !important;
  display: flex;
  align-items: center;
}

.padding-modal {
  padding: 20px 16px;
}

.btn {
  background-color: rgb(38, 38, 38);
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 15px;
  border-radius: 7px;
  min-width: 100px;
  font-family: "Inter", sans-serif;
}

.create_project {
  background-color:#262626;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  width: 190px;
  font-family: "Inter", sans-serif;
  height: 46px;
}

.circle-loader {
  position: relative;
  width: 50px; 
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 1.5s linear infinite;
}

.dot {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: black;
  border-radius: 50%;
  animation: dotAnimation 1.5s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dotAnimation {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.dot:nth-child(1) {
  top: 0;
  left: 50%;
  transform-origin: 0 100%;
  animation-delay: 0s;
}

.dot:nth-child(2) {
  top: 14%;
  left: 86%;
  transform-origin: 100% 0;
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  top: 50%;
  left: 100%;
  transform-origin: 100% 50%;
  animation-delay: 0.4s;
}

.dot:nth-child(4) {
  top: 86%;
  left: 86%;
  transform-origin: 100% 100%;
  animation-delay: 0.6s;
}

.dot:nth-child(5) {
  top: 100%;
  left: 50%;
  transform-origin: 50% 100%;
  animation-delay: 0.8s;
}

.dot:nth-child(6) {
  top: 86%;
  left: 14%;
  transform-origin: 0 100%;
  animation-delay: 1s;
}

.dot:nth-child(7) {
  top: 50%;
  left: 0;
  transform-origin: 0 50%;
  animation-delay: 1.2s;
}

.dot:nth-child(8) {
  top: 14%;
  left: 14%;
  transform-origin: 0 0;
  animation-delay: 1.4s;
}
.loader{
  background-color: #ffffff;
  height: 138px;
  width: 138px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.centered-loader{
  display: flex;
  justify-content: center;
  align-items: center;
}
.transparent{
  background-color: transparent;
}