.doc-Container {
  height: 100%;
  width: 90%;
  margin: auto;
  position: relative;
}

.doc-Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  border: 1px solid transparent;
  background-color: white;
  border-radius: 10px;
  gap: 10px;
  font-size: 16px;
  margin-top: 20px;
  position: relative;
}

.doc-Content>div {
  flex: 1;
  padding: 0 10px;
}

.doc-title {
  font-family: Inter;
  font-size: 13px !important;
  font-weight: 700;
}

.doc-type {
  color: gray !important;
  font-size: 12px;
  font-weight: 600;

}

.doc-seo {
  display: block;
  font-weight: 800;
  font-size: 16px;

}

.doc-status-parent {
  display: flex;
  justify-content: start;
  position: relative;
  cursor: pointer;
}

.status-dropdown {
  width: auto;
  min-width: 70px;
  height: auto;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  border: transparent;
  outline: none;
  font-size: 11px;
  font-weight: 600;

}

/*.published{*/
/*  background-color:#FFF0F9;*/
/*  color:#ee6ebb*/
/*}*/
.waiting-for-approval {
  background-color: #E8F3FF;
  color: #75aeef
}

.progress {
  color: #8065ED;
  background-color: #ECE8FF;
}

.doc-status-icon {
  font-size: 13px;
  padding-left: 5px;

}

.doc-detail {
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 25px !important;
  height: 25px;
  padding: 6px;
  cursor: pointer;
  position: relative;
}

.doc-search-icon {
  color: rgba(0, 0, 0, .45);
  background-color: lavender;

  padding: 5px;
  border-radius: 100%;
}

.doc-search-input {
  width: 22% !important;
  float: right !important;
  margin-bottom: 20px;
  height: 40px;
  outline: none;
}

.doc-main-heading {
  display: flex;
  font-weight: 700;
  font-size: 20px;
  align-items: center;
  margin-top: 30px;

}

.doc-main-heading-content {
  margin-left: 18px;
  font-weight: 900
    /* font-family: 'Lalezar', sans-serif; */
}

.create-doc-text {
  width: 400px;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 25px !important;
  margin-top: 25px;
  color: #000000;
}

.del-btn {
  background-color: #ffffff;
  border: 1px solid rgba(229, 229, 229, 1);
  width: 183px;
  border-radius: 4px;
  padding: 10px !important;
  cursor: pointer;
  position: absolute;
  right: 84px;
  top: 63px;
  z-index: 999;
}

.del-flex {
  display: flex;
  align-items: center;
  gap: 10px;

}

.del-text {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.del-bg {
  background-color: #ffffff;
  padding: 20px;
}

.del-bg:hover {
  background-color: #edf6ff;
  border-radius: 4px;
  padding: 20px;
}

.publish {
  background-color: #ffffff;
  border: 1px solid rgba(229, 229, 229, 1);
  padding: 10px;
  border-radius: 4px;
  width: 257px;
  top: 48px;
  position: absolute;
  text-align: left;
  right: 107px;
  z-index: 999;
  cursor: pointer;
}

.publish-div1 {
  padding: 20px;
}

.publish-p1 {
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  font-family: "Inter";
}

.publish-div {
  background-color: #edf6ff;
  border-radius: 4px;
  padding: 10px 20px;
}

.publish-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title{
  display: flex;
  justify-content: space-between;
  gap: 5px
}

.edit-able{
  cursor: text;
  outline: none;
  border: none;
}
.text-area{
  height: 65px;
}

.edit-able[placeholder]:empty:before {
  content: attr(placeholder);
 color:#4BA9FF;
 font-weight: 700;
 font-size: 12px;
 font-family: "Inter"; 
}

.text-area-max{
  height: auto;
  min-height: 630px;
  margin-top: 0px !important;
}
.edit-able p , li{
font-size: 18px !important;
font-family: "Inter";
}