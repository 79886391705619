.left {
    background-color: #f6f6f6;
    width: 80%;

}

.left-pading {
    padding: 10px 20px;
    height: 83vh;
}

.copyImg {
    display: flex;
    justify-content: end;
    box-shadow: 10px black;
}

.imgCopy {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 14px;
    box-shadow: 0px 0px 12px -6px black;
    cursor: pointer;
}

.Imageadd {
    background-color: black;
    padding: 10px;
    border-radius: 50px;
}

.flexMeta {
    display: flex;
    align-items: center;
    gap: 15px;
}

.metaText {
    color: rgba(75, 169, 255, 1);
    font-size: 12px;
    font-weight: 700;
}

.textBox {

    border: 1px dashed rgba(184, 184, 184, 1);
    width: 27%;
    padding: 30px;
    margin-top: 20px;
    margin-left: 5px;
    border-radius: 4px;
    cursor: text;
}


.flexbtn-writemore{
    display: none;
}
.makebtn-hover:hover .flexbtn-writemore{
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 1rem;
    margin-left: 5px;
}

.titleBox {
    width: 27%;
    margin-top: 4rem;
    border-radius: 4px;
    padding: 20px 10px;
}

.hdngMetaDscp{
    color: #A8A8A8;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 600;
    padding: 0px 10px;
}
.paraMetaDscp{
    color:#000000 ;
    font-size:14px ;
    font-weight: 500;
    font-family: "Inter";
    width: 98%;
    padding: 0px 10px;
    background: transparent;
}

.textInput::placeholder {
    color: rgba(75, 169, 255, 1);
    font-size: 12px;
    font-weight: 700;

}

.titleInput::placeholder , .builder-textarea::placeholder {
    color: rgba(75, 169, 255, 1);
    font-size: 12px;
    font-weight: 700;

}

.textInput {
    padding: 10px;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    border: none;
    outline: none;
    background: transparent;
    font-family: "Inter";

}


.builder-textarea {
    padding: 10px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    border: none;
    outline: none;
    background: transparent;
    font-family: "Inter";
    line-height: 29.5px;
    resize: none;


}

.titleInput {
    padding: 5px;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    border: none;
    outline: none;
    background: transparent;
    margin-left: 20px;

}

.Fotter {
    padding: 0px 20px;
}

.flexFotter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    border: 1.5px solid black;
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.words {
    font-size: 12px;
    font-weight: 700;
}

.Container {
    display: flex;
    height:90.9vh;
}

.right {
    width: 30%;
    background-color: white;
}

.score {
    font-size: 24px;
    font-weight: 400;
    font-family: "Lalezar";
}

.meter {
    display: flex;
    align-items: center;
    gap: 13px;
    margin-top: 20px
}

.rectangle {
    width: 74%;
    border: 1px solid rgba(236, 236, 236, 1);
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 30px;
    position: relative;

}

.avgScore {
    position: absolute;
    right: 70px;
    top: -18px;
}

.perc {
    font-size: 38px;
    font-weight: 400;
    font-family: "Lalezar";
}

.keyword-class {
    color: rgba(151, 151, 151, 1);
    font-size: 12px;
    font-weight: 700;
}

.flexScore {
    display: flex;
    gap: 8px;
    margin-top: 4px;
    align-items: center;
}

.detail-page-keyword {
    color: rgba(38, 38, 38, 1);
    font-size: 24px;
    font-weight: 400;
    font-family: "Lalezar";
}


.seo-img{
    width: 18px;
    margin-bottom: 0.22rem;
}
.scoring {
    width: 100%;
    display: flex;
    border-top: 1px solid rgba(151, 151, 151, 1);
    border-bottom: 1px solid rgba(151, 151, 151, 1);
}

.pdng {
    padding: 20px;
}

.brdr {
    border-right: 1px solid rgba(151, 151, 151, 1);
    padding: 15px;
    width: 100%;

}

.brdr-1 {
    padding: 15px;

}

.mid {
    padding:10px 20px;
    font-family: "Inter";
}

.midFlex {
    display: flex;
    justify-content: space-between;
    background-color: rgba(246, 246, 246, 1);
    padding: 5px;
    border-radius: 8px;
    align-items: center;
    width: 100%;
}

.Active {
    background-color: white;
    padding: 10px 18px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;


}

.div-2-builder {
    color: rgba(151, 151, 151, 1);
    font-size: 12px;
    font-weight: 400;
    padding: 10px 18px;
    cursor: pointer !important;
}

.typodiv-1 {
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100px;
}

.typoflex {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    padding: 3px 20px;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    gap: 10px;
}

.typoContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.typoImg {
    background-color: black;
    padding: 10px;
    border-radius: 5px;
}

.typoImgAdd {
    background-color: black;
    padding: 8px;
    border-radius: 5px;
}

.typoImgsub {
    background-color: black;
    padding: 13px;
    border-radius: 5px;
}

.navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
    background-color: white;
    height: 9vh;
}

.navLeft {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 20px;
    gap: 15px;
}

.navRight {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    width: 100%;
    gap: 15px;
    justify-content: end;
}
.not-allowed{
    cursor: not-allowed;
}
.backArrow {
    border: 1px solid rgba(236, 236, 236, 1);
    border-radius: 20px;
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.editButton {
    height: 16px;
    width: 16px;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 14px;
    padding: 6px;
    cursor: pointer;
}

.newDocText {
    font-size: 18px;
    font-weight: 700;
}

.targtFlex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    /* border: 1px solid rgba(236, 236, 236, 1); */
    padding: 15px;
    border-radius: 4px;
}

.downImg {
    background-color: rgba(236, 236, 236, 1);
    border-radius: 11px;
    width: 21px;
    height: 21px;
    cursor: pointer;
}

.targetText {
    font-size: 12px;
    font-weight: 600;
}

.toneFlex {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: space-between;
    border: 1px solid rgba(236, 236, 236, 1);
    padding: 13px 15px;
    border-radius: 4px;
    position: relative;
    cursor: not-allowed;
}

.tone-dropdown {
    position: absolute;
    top: 60px;
    background-color: #ffffff;
    border: 1px solid rgba(221, 232, 236, 1);
    padding: 10px;
    border-radius: 8px;
    width: 288px;
    z-index: 1;
    cursor: pointer;
    left: 0;
    cursor: pointer;
    overflow: hidden scroll;
    height: 355px;
  }

  .tone-dropdown::-webkit-scrollbar {
    width: 10px;
    background-color: black;

}

.tone-dropdown::-webkit-scrollbar-button {
    background-color: whitesmoke;
}

.tone-dropdown::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
}

.tone-dropdown::-webkit-scrollbar-track {
    background-color: whitesmoke;
}
.element-section-keyword{
    width: 98%;
}
.element-section{
    height: 47.5vh;
    overflow-y:  auto;
    width: 98%;

}
.gen-more{
    display: flex;
    justify-content: center;
}

.element-section::-webkit-scrollbar {
    width: 10px;
    background-color: black;
}

.element-section::-webkit-scrollbar-button {
    background-color: white;
}

.element-section::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
}

.element-section::-webkit-scrollbar-track {
    background-color: white;
}

.toneDownImg {
    background-color: #F6F6F6;
    border-radius: 23px;
    padding: 5px;
    width: 12px;
    height: 14px;
    cursor: pointer;
}

.toneText {
    font-size: 12px;
    font-weight: 600;
}

.saveFlex {
    display: flex;
    align-items: center;
    width: 10%;
    justify-content: center;
    padding: 14px 15px;
    border-radius: 5px;
    background-color: black;
    cursor: pointer;
}

.saveDownImg {
    cursor: pointer;
    margin-top: 1px;
}

.saveText {
    font-size: 14px;
    font-weight: 500;
    color: white;
}

.newButtonFoter{
    border: 1.5px solid black;
    text-align: center;
    padding: 12px 0px;
    width: 434px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter";
}

@media screen and (max-width: 1520px) {
    .typodiv-1{
        width: 100%;
        height: 90px;
    }
    .typoflex {
        padding: 3px 15px;
            
        }
        .typoImg{
            width: 14px;
            padding: 8px;
        }
        .typoImgAdd {
            padding: 5px;
        }
        
        .typoImgsub {
            padding: 8px;
        }
        .brdr{
            padding: 10px;
        }
        .brdr-1{
            padding: 10px;
        }
        .navRight {
            padding: 15px;
        }
        .mid {
            padding: 15px;
        }
        .midFlex{
            width: 98%;
        }
}
@media screen and (max-width: 1252px) {
    .Container {
        display: flex;
    }
    .typodiv-1{
        width: 100%;
        height: 70px;
    }
    .meter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    .navBar{
        padding: 0;
    }
    .rectangle {
        width: 100%;
    }

    .midFlex {
        padding: 5px;
    }
    .typoflex {
    padding: 3px 10px;
    font-size: 10px;
        
    }
    .titleBox {
        width: 50%;
    }
    .left-pading {
        padding: 20px;
    }
   
    .brdr{
        padding: 5px;
    }
    .brdr-1{
            padding: 5px;
        }    
        .toneFlex {
            width: 80%;
            padding: 9px;
        }
        .detail-page-keyword{
            font-size: 20px;
        }
        .seo-img {
            width: 18px;
        }
        .div-2-builder{
            padding: 8px 10px;
        }
        .Active {
            padding: 8px 15px;
        }
        .pdng{
            padding: 10px;
        }
        .perc {
            font-size: 28px;
            font-weight: 400;
        }
        .score {
            font-size: 18px;
        }
}

.border {
    border: 1px dashed rgba(184, 184, 184, 1);

}

.no-border {
    border: none;
    width: 99%;
    font-size: 24px !important;
    padding: 0 !important;
    margin-top: 0 !important;
}

.save-publish {
    background-color: #ffffff;
    border: 1px solid rgba(229, 229, 229, 1);
    padding: 10px;
    border-radius: 4px;
    width: 315px;
    top: 230px;
    position: absolute;
    text-align: left;
    z-index: 1;
    cursor: pointer;
}

.save-modal-padding {
    padding: 15px 0px;
}

.save-modal-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.active-status {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
}


.non-active {
    background-color: #edf6ff;
    border-radius: 4px;
    padding: 15px 10px;
}

.status-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E5E5E5;
    padding: 0px 17px;
    border-radius: 5px;
    position: relative;
    width: 300px;
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
}

.status-input-img {
    background-color: #F6F6F6;
    border-radius: 23px;
    padding: 5px;
    width: 12px;
    height: 14px;
    cursor: pointer;
}

.status-input-inner {
    border: none
}

.sav-status-dropdown {
    width: auto;
    min-width: 70px;
    height: auto;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    border: transparent;
    outline: none;
    font-size: 11px;
    font-weight: 600;
}

.createArrow {
    border-radius: 20px;
    background-color: rgba(246, 246, 246, 1);
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.createTitle {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.createTitleFlex {
    display: flex;
    align-items: center;
    gap: 10px;
}

.createTitleText {
    font-size: 14px;
    font-weight: 500;
}

.insertText {
    color: rgba(75, 169, 255, 1);
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}

.titlePadding {
    padding: 5px 20px;
}

.titleBorder {
    border: 1px solid rgba(229, 229, 229, 1);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: start;
    justify-content: space-between;
    gap: 2px
}

.titleImageFlex {
    display: flex;
    align-items: start;
    gap: 10px;
}

.titleParagraph {
    margin-top: 4px;
}

.titleCount {
    color: white;
    background-color: rgba(69, 202, 137, 1);
    width: 25px;
    text-align: center;
    border-radius: 30px;
    padding: 5px;
}


.addKeywordText {
    display: flex;
    justify-content: end;
    color: var(--Sky, rgba(75, 169, 255, 1));
    font-size: 14px;
    padding: 15px;
    font-weight: 700;
    cursor: pointer;
}

.hidingsOfaddKeyword {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px;
    border-top: 1px solid rgba(236, 236, 236, 1);
    border-bottom: 1px solid rgba(236, 236, 236, 1);
    font-size: 12px;
    font-weight: 700;
}

.hiding1Keyword {
    width: 60%;
}

.hiding2KD {
    width: 20%;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}

.hiding3Volume {
    width: 20%;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}

.custom-checkbox {
    display: inline-block;
    position: relative;
}

.custom-checkbox input[type="checkbox"] {
    display: none;
}

.custom-checkbox label {
    width: 20px;
    height: 20px;
    border: 2px solid #999;
    border-radius: 50px;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;
}

.custom-checkbox input[type="checkbox"]:checked+label {
    background-color: white;
    border-color: #072b39;
}

.custom-checkbox label:after {
    content: '';
    position: absolute;
    top: 42%;
    left: 49%;
    width: 22px;
    height: 22px;
    background-image: url('../../assets//CheckboxTick.svg');
    background-size: cover;
    transform: translate(-50%, -50%);
    display: none;
}

.custom-checkbox input[type="checkbox"]:checked+label:after {
    display: block;
}

.addKeywordsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    border-bottom: 1px solid rgba(236, 236, 236, 1);
}

.keyWordFleximg {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 60%;
}

.addKeywordText1 {
    font-size: 12px;
    font-weight: 600;
}

.addKeyWordnmbrwidth {
    width: 20%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addKeyWordvolwidth {
    width: 20%;
    text-align: center;
}

.addKeyWordnmbrcolor {
    width: 28px;
    height: 28px;
    color: white;
    border-radius: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
}

.addKeyWordvolwidth {
    font-size: 12px;
    font-weight: 700;
}


.serpLine1ParagraphFlexList {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-bottom: 1px  #ECECEC solid ;
}

.serpLineNmbr {
    font-size: 22px;
    font-weight: 800;
}

.serpParagrph {
    border-left: 1px solid rgba(221, 221, 221, 1);
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
}

.serpPargrphLink {
    font-size: 12px;
    font-weight: 500;
    color: rgba(75, 169, 255, 1);
    cursor: pointer;
    margin-top: 10px;
}

.serpHeightFix {
    min-height: 5vh;
    max-height: 46vh;
    overflow-y: scroll;
}

.viewAllbtn {
    font-size: 14px;
    font-weight: 500;
    border: 1.5px solid black;
    border-radius: 4px;
    text-align: center;
    padding: 15px;
    cursor: pointer;
}

.viewAllbtnpdng {
    padding: 25px;
}

.serpPargrphLink a {
    color: rgba(75, 169, 255, 1) !important;

}

.serpTableParent{
    padding: 20px 0px 0px 20px;
}
.serpHeading{
    font-size: 38px;
    font-weight: 400;
    font-family: "lalezar";
}
.serpTableHidingFlex{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.serpTableTopWidth{
    display: flex;
    align-items: center;
    justify-content: end;
    border-bottom: 1px solid rgba(236, 236, 236, 1);
    padding: 10px 0px;
    font-size: 12px;
    font-weight: 700;
    font-family: "Inter";
    width: 100%;
}
.serph1{
   width: 70px; 
   text-align: center;
}
.serph2{
   width: 100px; 
   text-align: center;
}
.serph3{
   width: 120px; 
   text-align: center;
}
.serph4{
   width: 70px; 
   text-align: center;
}
.serph5{
   width: 70px; 
   text-align: center;
   margin-right: 15px;
}
.serpLine1Flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid rgba(236, 236, 236, 1);
}
.serpLine6Flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
}
.serpLine1ParagraphFlex{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 40%;
}
.serpLineNmbr{
    font-size: 22px;
    font-weight: 800;
}
.serpParagrph{
    border-left: 1px solid rgba(221, 221, 221, 1);
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
}
.serpPargrphLink{
    font-size: 12px;
    font-weight: 500;
    color: rgba(75, 169, 255, 1);
    cursor: pointer;
}
.serpTablenmbrsFlex{
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    width: 60%;
}
.wordNo{
   width: 70px; 
   text-align: center;
}
.outboundLinks{
   width: 100px; 
   text-align: center;
}
.semanticKeywords{
   width: 120px; 
   text-align: center;
}
.titleScore{
   width: 70px; 
   text-align: center;
}
.seoScore{
   width: 70px; 
   text-align: center;
   margin-right: 15px;
}
.serpTableFooter{
    background-color: rgba(246, 246, 246, 1);
    border-radius: 0px 0px 20px 20px;
    padding: 20px;
}
.serpFooterImgs{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}
.serpFooterLeftImg{
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 30px;
    height: 27px;
    width: 27px;
    cursor: pointer;
}
.serpFooterRightImg{
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 30px;
    height: 27px;
    width: 27px;
    cursor: pointer;
}
@media screen and (max-width:870px) { 
    .serpLine1Flex{
        flex-direction: column;
    }
    .serpLine1ParagraphFlex{
     display: none;
    }
    .serpTablenmbrsFlex{
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .serpTableHidingFlex{
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
}


.ImagesHiding{
    background-color: rgba(246, 246, 246, 1);
    display: flex;
    justify-content: space-between;
    padding: 4px;
    border-radius: 8px;
    align-items: center;
}
.ImagesHidingPadng{
    padding: 0px 20px;
}
.active-images{
    background-color: white;
    padding: 12px 80px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: grey 1px 2px 5px -1px;
    text-align: center;
}
.images-tab{
    color: rgba(151, 151, 151, 1);
    padding: 12px 80px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
}
.bgImgOfStock{
    position: relative;
}
.Doteimg{
    position: absolute;
    top: 7px;
    left: 7px;
    cursor: pointer;
}
.download-image{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.ImageDownload{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding: 3px;
    flex-wrap: wrap;
    gap:10px;
    overflow: hidden auto;
    height: 44vh;
}
.ImageDownload::-webkit-scrollbar {
    width: 10px;
    background-color: black;
}

.ImageDownload::-webkit-scrollbar-button {
    background-color: white;
}

.ImageDownload::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
}

.ImageDownload::-webkit-scrollbar-track {
    background-color: white;
}

.ImageDownloadLine2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
}
.random-avg-popover-content-right {
    width: 408px;
    height: 130px;
    background-color: black;
    color: #ffffff;
    line-height: 20px;
    text-align: left;
    border-radius: 15px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Titillium Web";
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .random-avg-popover-content-right::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 435px;
    right: 0px;
    width: 25px;
    height: 25px;
    background-color: black;
    border-top: none;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transform: rotate(45deg);
  }
  .firstAvgProjectText{
    font-size: 24px;
    font-family: 'Lalezar';
    font-weight: 400;
  }
  .firstAvgProjectPara{
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    margin-top: 20px;
  }
.showAvgMsgCheckBoxButton{
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    border: 1px solid white;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
}
.showAvgMsgFotterFlex{
    display: flex;
    justify-content: end;
}
.showAvgMsgPositionAdjust{
    position: absolute;
    right: 110px;
    top: -23px;
}
.margin-0{
    margin-top: 0;
    margin-bottom: 0;
}
.progressBarFillImagesclr3 {
    width: 7%;
}
.progressBarFillImagesclr2 {
    width: 7%;
}
.progressBarFillImagesclr1 {
    width: 7%;
}

.progressBar {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 30px;
    text-align: center;
    padding: 5px;
    position: relative;
}
.progressBarFillImagesclr3 {
    height: 100%;
    background: linear-gradient(90deg, #FFB84D 0%, #FFFA83 48.28%, #5BF38F 95.6%);
    border-radius: 5px;
    transition: width 0.3s;
    border-radius: 30px 0px 0px 30px;

}
.progressBarFillImagesclr2{
    background: linear-gradient(90deg, #FF2A5D 0%, #FFAD4D 95.6%);
    height: 100%;
    border-radius: 5px;
    transition: width 0.3s;
    border-radius: 30px 0px 0px 30px;
}
.progressBarFillImagesclr1 {
    height: 100%;
    background: linear-gradient(90deg, #FFB84D 0%, #FFFA83 48.28%, #5BF38F 95.6%);
    border-radius: 5px;
    transition: width 0.3s;
    border-radius: 30px 0px 0px 30px;
}

.avgScore {
    position: absolute;
    /* right: -8px; */
    top: -18px;
}

.rectangle {
width: 74%;
border: 1px solid rgba(236, 236, 236, 1);
display: flex;
align-items: center;
padding: 3px;
border-radius: 30px;


}
.seoMeterPosition{
position: relative;
}

.perc {
font-size: 38px;
font-weight: 400;
}
.flexBar{
display: flex;
align-items: center;
margin-top: 30px;
gap: 13px;
}
.add-content-scroll{
    overflow: hidden scroll;
    height: 70vh;
}

.add-content-scroll::-webkit-scrollbar {
    width: 10px;
    background-color: black;

}

.add-content-scroll::-webkit-scrollbar-button {
    background-color: whitesmoke;
}

.add-content-scroll::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
}

.add-content-scroll::-webkit-scrollbar-track {
    background-color: whitesmoke;
}
.st-images{
    width: 210px;
    height: 181px;
    object-fit: cover;
    border: none;
    border-radius: 8px;
}
.keyword-list{
    overflow: hidden scroll;
    height: 48vh;
}
.keyword-list::-webkit-scrollbar {
    width: 10px;
    background-color: black;
}

.keyword-list::-webkit-scrollbar-button {
    background-color: white;
}

.keyword-list::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 20px;
}

.keyword-list::-webkit-scrollbar-track {
    background-color: white;
}
.textHoverArrow {
    background-color: rgb(54, 54, 54);
    color: white;
    border-radius: 4px;
    padding: 6px;
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    font-family: "Inter";
    display: none;
    width: 100px;
}

.imgArrowHover {
    cursor: pointer;
    position: relative; /* Ensure positioning context for the tooltip */
}

.imgArrowHover:hover .textHoverArrow {
    display: block;
}
.disabled-description{
    cursor: not-allowed;
    opacity: 0.4;
}
.centered-element{
    display: flex;
    align-items: center;
    justify-content: center;
}