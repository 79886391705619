.container {
  background-color: #f9fcfe;
  min-height: 100vh;
  padding: 30px 10%;
}

header {
  background-color: black;
  padding: 15px;
  border-radius: 5px;
}

.flexHead {
  display: flex;
  align-items: center;
  gap: 4px;
}

.textBrevy {
  font-size: 2rem;
  font-weight: 400;
  color: white;
  font-family: 'Lalezar';
}

.leftSideBar {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  border-radius: 8px;
  margin-top: 30px;
  width: 100%;
}

.active {
  background: rgba(75, 169, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600 !important;
}

.termUse {
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Inter';
}

.mainSection {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.rightSideBar {
  margin-top: 35px;
  width: 100%;
}

.uses {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: 'Lalezar';
}

.use-1h {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 30px;
  font-family: 'Inter';
}

.use-1p {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
  font-family: 'Inter';
}

@media (min-width: 768px) {
  .mainSection {
    flex-direction: row;
  }

  .leftSideBar {
    width: 30%;
    height: auto;
  }

  .rightSideBar {
    width: 70%;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 30px 120px;
  }

  .textBrevy {
    font-size: 2.5rem;
  }

  .leftSideBar {
    width: 20%;
  }

  .rightSideBar {
    width: 80%;
  }

  .uses {
    font-size: 3rem;
  }

  .use-1h, .use-2h {
    font-size: 1.5rem;
  }

  .use-1p {
    font-size: 1.1rem;
  }
}

