.card-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
}

.card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s;
}

.card:hover {
    transform: translateY(-5px);
}

.no-box-shadow {
    box-shadow: unset !important;
}

.display_draw_inner {
    padding: 10px 10px 10px 30px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.display_draw_inner h4 {
    font-size: 14px;
    font-weight: 500;
}

.addicon {
    height: 45px;
    width: 45px;
    cursor: pointer;
}

.card-header h3 {
    font-size: 18px;
    color: #262626;
    font-weight: 700;
    margin-top: 15px;
}

.display_draw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.display_draw h4 {
    margin: 0;
    margin-left: 15px;
    font-size: 24px;
}

.card-body {
    margin-top: 10px;
}

.status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: bold;
}

.published {
    background-color: #FFF0F9;
    color: #ED65B7;
    font-size: 12px;
    font-weight: bold;
}

.approval {
    background-color: #E8F3FF;
    color: #65A4ED;
    font-size: 12px;
    font-weight: bold;
}

.done {
    background-color: #E3FFE7;
    color: #63CA73;
    font-size: 12px;
    font-weight: bold;
}

.in-progress {
    background-color: #ECE8FF;
    color: #8065ED;
    font-size: 12px;
    font-weight: bold;
}

.card-footer {
    margin-top: 15px;
    color: #aaa;
    font-size: 0.875rem;
}

.project_name {
    color: #979797;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
}

.project_name a {
    color: #979797;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
}

.para {
    color: #979797;
    font-weight: 500;
    font-size: 12px;
    margin-top: 10px;
    width: 70%;
    overflow: hidden;
    white-space: nowrap; /* Single line */
    text-overflow: ellipsis; /* Adds ... if text is too long */
    cursor: pointer;
    position: relative;
}

/* Tooltip styling for truncated text */
.para::after {
    content: attr(data-full-text); /* Use the full description as tooltip content */
    display: none; /* Hide tooltip by default */
    position: absolute;
    left: 0;
    top: 100%; /* Position below the element */
    background-color: #333;
    color: #fff;
    padding: 8px;
    font-size: 12px;
    border-radius: 4px;
    white-space: normal;
    width: max-content;
    max-width: 250px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Show tooltip on hover */
.para:hover::after {
    display: block;
    transform: translateY(5px);
}

.seo_p {
    color: #979797;
    font-weight: bold;
    font-size: 12px;
    margin-top: 70px !important;
    margin: 0;
}

.content_container {
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
}

.recent {
    font-family: "Lalezar";
    font-size: 38px;
    font-weight: 400;
}

.score2 {
    font-size: 38px;
    font-weight: 400;
    margin: 0;
    font-family: "Lalezar";
}

.display_head {
    display: flex;
    align-items: center;
    gap: 100px;
}

.date_ {
    font-size: 12px;
    color: #979797;
    font-weight: bold;
}

.menus {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

.dash {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dash_sub {
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons_ {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.no-projects-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
}

.no-projects-message p {
    text-align: center;
}
