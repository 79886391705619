.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color:
          #FFFFFF;
  /* border-top: 1px solid #e0e0e0; */
  padding: 30px 0px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.cookie_img_container {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 24px;
  font-family: "Lalezar";
  font-weight: 400;
  margin-bottom: 20px;
  padding-left: 50px;
}

.cookie-consent-content {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 20px;
  align-items: center;
}

.privacy_policy_cookie {
  color: #4BA9FF;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.cookie-consent-content p {
  margin: 0;
  color: #333;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 1.5;
}

.cookie-consent-buttons {
  display: flex;
  gap: 10px;
}

.reject-button,
.accept-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

.reject-button {
  background-color:
          #FFFFFF;
  color: #262626;
  border: 1px solid #262626;
}

.accept-button {
  background-color: #262626;
  color: #FFFFFF;
  outline: none;
}

.reject-button:hover,
.accept-button:hover {
  opacity: 0.8;
}